window.addEventListener('turbolinks:load', () => {
  $('#btn_payment').on('click', function(){
    let form = $(this).parent().parent().parent();
    let count = 0;
    $(form).find('input').each(function() {
      if ($(this).prop('required') && $(this).val() == '') {
        $(this).addClass('redborder');
        count = count + 1;
      } else {
        $(this).removeClass('redborder');
      }
    });
    if(count > 0){
      return
    }
    document.getElementById('modal_loading').style.display = 'block';
    $.ajax({
      url: '/create_payment.json',
      type: 'post',
      dataType: 'json',
      data: $(form).serialize(),
      success: function(data) {
        document.getElementById('modal_loading').style.display = 'none';
        console.log(data)
        if(data.status){
          Swal.fire(
            data.title,
            data.message,
            'success'
          ).then(function() {
            setTimeout(function () {
              window.location.href = "/boletos?user%5BIdentity%5D=" + $(".Identity").val();
            }, 1000);
          })
        }else{
          Swal.fire(
            data.title,
            data.message,
            'warning'
          )
        }
      }
    });
  })
  var selector = $(".Identity");
  if (selector){
    var im = new Inputmask(["999.999.999-99", "99.999.999.9999/99"]);
    im.mask(selector);
  }

  var selector = $(".ZipCode");
  if (selector) {
    var im = new Inputmask("99999-999");
    im.mask(selector);
  }

  //var selector = $(".Phone");
  //if (selector) {
  //  var im = new Inputmask(['(99)9999-9999', '(99)99999-9999']);
  //  im.mask(selector);
  //}

  var selector = $(".date");
  if (selector) {
    var im = new Inputmask("99/99/9999");
    im.mask(selector);
  }

  var selector = $(".hour");
  if (selector) {
    var im = new Inputmask("99:99");
    im.mask(selector);
  }

  $(".decimal").maskMoney({thousands:'.', decimal:',', allowZero:true})

  $(".ZipCode").blur(function(event){
    if( $(".ZipCode") && $(".ZipCode").val().length == 9){
      $.getJSON('https://viacep.com.br/ws/' + $(".ZipCode").val().replace("-","") + '/json/', function(data) {
        console.log(data)
        if (data.erro == undefined){
          $(".Street").val(data.logradouro)
          $(".District").val(data.bairro)
          $(".CityName").val(data.localidade)
          $(".StateInitials").val(data.uf)
        }
      }).fail(function(jqxhr){
        $("#customer_zipcode").focus();
      });
    }
  });
})

window.recaptchaCallback = function() {
  document.getElementById("btn_my_boletos").removeAttribute("disabled");
};

window.openProductName = function(title, message) {
  Swal.fire(
    title,
    message,
    'success'
  )
}

window.openClient = function(client) {
  Swal.fire(
    "",
    client,
    'success'
  )
}
