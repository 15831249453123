window.addEventListener('turbolinks:load', () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ref = urlParams.get('ref')
  if(ref != null){
    document.cookie = "ref=" + ref + ";domain=.trendgrupos.com.br;path=/";
  }
  $('.select-groups').select2({
    closeOnSelect: false
  });
  $('.select').select2({
    templateResult: hideSelected,
  });
  $('.select-tags').select2({
    tags: true
  });
  $('.select-whatsapp-redirect').select2({
    tags: true
  });

  $("form").submit(function(){
    $('.modal-photo').show()
  });

  const phoneInputField = document.querySelector(".phone");
  if (phoneInputField) {
    const phoneInput = window.intlTelInput(phoneInputField, {
      initialCountry: 'br',
      hiddenInput: "phone",
      nationalMode: true,
      placeholderNumberType: "MOBILE",
      separateDialCode: true,
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      });
  }

  var coll = document.getElementsByClassName("collapsible");
  var i;

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var content = this.nextElementSibling;
      if (content.style.display === "block") {
        content.style.display = "none";
      } else {
        content.style.display = "block";
      }
    });
  }

  $(".youtube-video").click(function () {
    let theModal = $(this).data("target"),
    videoSRC = $(this).attr("data-video"),
    videoSRCauto = videoSRC + "?modestbranding=1&rel=0&controls=0&showinfo=0&html5=1&autoplay=1";
    $(theModal + ' iframe').attr('src', videoSRCauto);
    $(theModal).on('hidden.bs.modal', function (e) {
      $(theModal + ' iframe').attr('src', '');
    })
  });
})

function hideSelected(value) {
  if (value && !value.selected) {
    return $('<span>' + value.text + '</span>');
  }
}
