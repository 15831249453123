$( document ).on('turbolinks:load', function() {
  if($('body').hasClass('webhook_notifications')) {
    $('#webhook_notification_messages').on('cocoon:before-insert', function(e, insertedItem, originalEvent) {
      select = $(insertedItem).find('select')[0]
      $(select).on('change', '', function (e) {
        checkSelect(this)
      });
    });

    $('#webhook_notification_messages').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
      if($('.row_webhook_notification_message_fields').not('[style*="display: none"]').length >= 5){
        $('.add_webhook_notification_message_fields').hide()
      }
    });

    $('#webhook_notification_messages').on('cocoon:after-remove', function(e, insertedItem, originalEvent) {
      if($('.row_webhook_notification_message_fields').not('[style*="display: none"]').length < 5){
        $('.add_webhook_notification_message_fields').show()
      }
    });

    $('.row_webhook_notification_message_fields').on('change', '', function (e) {
      select = $(this).find('select')[0]
      checkSelect(select)
    });

    $('.row_webhook_notification_message_fields').each(function(index, value) {
      select = $(value).find('select')[0]
      checkSelect(select)
    });

    if($('.row_webhook_notification_message_fields').not('[style*="display: none"]').length < 5){
      $('.add_webhook_notification_message_fields').show()
    }
  }
})

function checkSelect(select){
  if($(select).val() == '2' || $(select).val() == '3' || $(select).val() == '4' || $(select).val() == '5' || $(select).val() == '12'){
    $(select).parent().next().next().next().show()
    if($(select).val() == '2' || $(select).val() == '12'){
      $($(select).parent().next().next().next().find('input')[0]).attr("accept", '.png, .jpg, .jpeg')
    }else if($(select).val() == '3'){
      $($(select).parent().next().next().next().find('input')[0]).attr("accept", 'video/*')
    }else if($(select).val() == '4'){
      $($(select).parent().next().next().next().find('input')[0]).attr("accept", 'audio/*')
    }else if($(select).val() == '5'){
      $($(select).parent().next().next().next().find('input')[0]).attr("accept", '.pdf')
    }
  }else{
    $(select).parent().next().next().next().hide()
  }
}
