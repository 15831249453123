// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'

import jQuery from "jquery"
window.jQuery = jQuery // <- "select2" will check this
window.$ = jQuery

import $ from 'jquery';
import select2 from 'select2';
import 'select2/dist/css/select2.css';
import Inputmask from "inputmask";
import "sweetalert2/dist/sweetalert2.min"

window.Swal = require('sweetalert2') // added here

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
//require("chart.js")
//require("Chart.extension.js")
require("jquery-scrollLock.min.js")
require("jquery.scrollbar.min.js")
require("argon.js")
require("webhook.js")
require("webhook_notification.js")
require("automation.js")
require("send_whatsapp.js")
require("custom.js")
require("sign_up.js")
require("campaign.js")
require("whatsapp.js")
require("plan.js")
require("jquery-maskmoney")
require("payment.js")
require("@nathanvda/cocoon")

global.toastr = require("toastr")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
