$( document ).on('turbolinks:load', function() {
  if($('body').hasClass('campaigns-campaigns_slug')){
    let slug = $(location).attr('pathname').split("/c/")[1]
    window.location.replace("/campaigns_slug_group?slug=" + slug);
  }
  if($('body').hasClass('campaigns')){
    $("#chkall").click(function(){
      if($("#chkall").is(':checked')){
        $("#campaign_groups > option").prop("selected", "selected");
        $("#campaign_groups").trigger("change");
      } else {
        $("#campaign_groups > option").removeAttr("selected");
        $("#campaign_groups").val(null).trigger('change');
        $('#campaign_groups').select2({
          closeOnSelect: false
        });
      }
    });
    $('.whatsapp_blocked_add').on('click', '', function (e) {
      $('.modal-photo').show()
      $.post("/whatsapp_blocked_add?whatsapp_blocked=" + $("#addNewNumber").val(), function(data, status){
        if(data.status){
          $("#addNewNumber").val('')
          $("#send_whatsapp_numbers").prepend("<div class='send_whatsapp_number_div' id=" + data.id + "><a href='javascript:void(0)' style='color: red' onclick='window.whatsappBlockedDel(this)'><i class='fa fa-trash' aria-hidden='true' style='font-size: 25px;'></i></a><span> " + data.whatsapp_number + '</span></div>');
        }else{
          alert(data.message);
        }
        $('.modal-photo').hide()
      });
    });

    window.whatsappBlockedDel = function (div){
      console.log(div)
      div = $(div).parent()[0]
      console.log(div)
      if(window.confirm("Confirm?")){
        $('.modal-photo').show()
        $.post("/whatsapp_blocked_del?whatsapp_blocked=" + $(div).attr('id'), function(data, status){
          if(data.status){
            div.remove();
          }else{
            alert(data.message);
          }
          $('.modal-photo').hide()
        });
      }
    }
  }
})
