window.addEventListener('turbolinks:load', () => {
  if($(location).attr('href').includes("/users/sign_up")) {
    setTimeout(() => {
      var urlParamsScript = new URLSearchParams(window.location.search);
      if(urlParamsScript.get('utm_source') != null){ $(".utm_source").val(encodeURIComponent(urlParamsScript.get('utm_source')))};
      if(urlParamsScript.get('utm_medium') != null){ $(".utm_medium").val(encodeURIComponent(urlParamsScript.get('utm_medium')))};
      if(urlParamsScript.get('utm_term') != null){ $(".utm_term").val(encodeURIComponent(urlParamsScript.get('utm_term')))};
      if(urlParamsScript.get('utm_campaign') != null){ $(".utm_campaign").val(encodeURIComponent(urlParamsScript.get('utm_campaign')))};
      if(urlParamsScript.get('utm_content') != null){ $(".utm_content").val(encodeURIComponent(urlParamsScript.get('utm_content')))};
      if(urlParamsScript.get('fbclid') != null){ $(".fbclid").val(encodeURIComponent(urlParamsScript.get('fbclid')))};
      if(urlParamsScript.get('gclid') != null){ $(".gclid").val(encodeURIComponent(urlParamsScript.get('gclid')))};
    }, "2000")
  }
})
