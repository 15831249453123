window.addEventListener('turbolinks:load', () => {
  if($('body').hasClass('send_whatsapps')) {
    flatpickr(".datepicker", {
      enableTime: true,
      dateFormat: "d/m/Y H:i",
      minDate: "today",
      time_24hr: true,
      defaultDate: $("#send_whatsapp_default_start_at").val() != '' ? $("#send_whatsapp_default_start_at").val() : changeTimezone(new Date(), "America/Sao_paulo").getTime(),
      minuteIncrement: 1
    });

    if($("#send_whatsapp_default_start_at").val() != ''){
      $("#send_whatsapp_start_at").val($("#send_whatsapp_default_start_at").val())
    }

    $('#send_whatsapp_messages').on('cocoon:before-insert', function(e, insertedItem, originalEvent) {
      select = $(insertedItem).find('select')[0]
      $(select).on('change', '', function (e) {
        checkSelect(this)
      });
    });

    $('#send_whatsapp_messages').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
      if($('.row_send_whatsapp_message_fields').not('[style*="display: none"]').length >= 5){
        $('.add_send_whatsapp_message_fields').hide()
      }
    });

    $('#send_whatsapp_messages').on('cocoon:after-remove', function(e, insertedItem, originalEvent) {
      if($('.row_send_whatsapp_message_fields').not('[style*="display: none"]').length < 5){
        $('.add_send_whatsapp_message_fields').show()
      }
    });

    $('.row_send_whatsapp_message_fields').on('change', '', function (e) {
      select = $(this).find('select')[0]
      checkSelect(select)
    });

    $('.row_send_whatsapp_message_fields').each(function(index, value) {
      select = $(value).find('select')[0]
      checkSelect(select)
    });

    if($('.row_send_whatsapp_message_fields').not('[style*="display: none"]').length < 5){
      $('.add_send_whatsapp_message_fields').show()
    }

    $('.remove_send_whatsapp_number').on('click', '', function (e) {
      div = $(this).parent()
      if(window.confirm("Confirm?")){
        $('.modal-photo').show()
        $.post("/remove_send_whatsapp_number?send_whatsapp_number_id=" + $(this).parent().attr('id'), function(data, status){
          if(data.status){
            div.remove();
          }else{
            alert('Error');
          }
          $('.modal-photo').hide()
        });
      }
    });

    $('.add_send_whatsapp_number').on('click', '', function (e) {
      $('.modal-photo').show()
      $.post("/add_send_whatsapp_number?send_whatsapp_id=" + $("#send_whatsapp_id").val() + "&send_whatsapp_number=" + $("#addNewNumber").val(), function(data, status){
        if(data.status){
          $("#addNewNumber").val('')
          $("#send_whatsapp_numbers").prepend("<div class='send_whatsapp_number_div' id=" + data.id + "><a href='javascript:void(0)' style='color: red' class='remove_send_whatsapp_number'><i class='fa fa-trash' aria-hidden='true' style='font-size: 25px;'></i></a><span> " + data.whatsapp_number + ', ' + data.name + ' </span></div>');
        }else{
          alert(data.message);
        }
        $('.modal-photo').hide()
      });
    });
  }
})

function checkSelect(select){
  if($(select).val() == '2' || $(select).val() == '3' || $(select).val() == '4' || $(select).val() == '5' || $(select).val() == '12'){
    $(select).parent().next().addClass('col-lg-4')
    $(select).parent().next().removeClass('col-lg-7')
    $(select).parent().next().next().show()
    if($(select).val() == '2' || $(select).val() == '12'){
      $($(select).parent().next().next().find('input')[0]).attr("accept", '.png, .jpg, .jpeg')
    }else if($(select).val() == '3'){
      $($(select).parent().next().next().find('input')[0]).attr("accept", 'video/*')
    }else if($(select).val() == '4'){
      $($(select).parent().next().next().find('input')[0]).attr("accept", 'audio/*')
    }else if($(select).val() == '5'){
      $($(select).parent().next().next().find('input')[0]).attr("accept", '.pdf')
    }
  }else{
    $(select).parent().next().removeClass('col-lg-4')
    $(select).parent().next().addClass('col-lg-7')
    $(select).parent().next().next().hide()
  }
  if($(select).val() == '6'){
    $($(select).parent().next().find('textarea')[0]).attr("placeholder", 'Informe o título do grupo')
  }else if($(select).val() == '7'){
    $($(select).parent().next().find('textarea')[0]).attr("placeholder", 'Informe o descrição do grupo')
  }else if($(select).val() == '8' || $(select).val() == '9' || $(select).val() == '10' || $(select).val() == '11' || $(select).val() == '12'){
    $($(select).parent().next().find('textarea')[0]).attr("placeholder", '')
  }else if($(select).val() == '13'){
    $($(select).parent().next().find('textarea')[0]).attr("placeholder", 'Digite o título do botão na primeira linha\nDigite o link do botão na segunda linha')
  }else{
    $($(select).parent().next().find('textarea')[0]).attr("placeholder", 'Utilize {n} para pegar o número do grupo, exemplo: Nome do grupo #{n} ...')
  }
}

function changeTimezone(date, ianatz) {

  // suppose the date is 12:00 UTC
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: ianatz
  }));

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  var diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract

}

window.validateFiles = function(inputFile) {
  var maxExceededMessage = "Tamanho máximo por aquivo (15 MB)";
  var extErrorMessage = "Apenas esses arquivos .jpg, .jpeg, .gif, .png, .mp3, .mov, .ogg ou mp4 são permitidos.";
  var allowedExtension = ["jpg", "jpeg", "gif", "png", "mp3", "mp4", "pdf", "mov", "ogg"];

  var extName;
  var maxFileSize = $(inputFile).data('max-file-size');
  var sizeExceeded = false;
  var extError = false;

  $.each(inputFile.files, function() {
    if (this.size && maxFileSize && this.size > parseInt(maxFileSize)) {sizeExceeded=true;};
    extName = this.name.split('.').pop();
    if ($.inArray(extName.toLowerCase(), allowedExtension) == -1) {extError=true;};
  });
  if (sizeExceeded) {
    window.alert(maxExceededMessage);
    $(inputFile).val('');
  };

  if (extError) {
    window.alert(extErrorMessage);
    $(inputFile).val('');
  };
}

window.validateCsvFiles = function(inputFile) {
  var maxExceededMessage = "Tamanho máximo por aquivo (15 MB)";
  var extErrorMessage = "Apenas arquivos .csv são permitidos.";
  var allowedExtension = ["csv"];

  var extName;
  var maxFileSize = $(inputFile).data('max-file-size');
  var sizeExceeded = false;
  var extError = false;

  $.each(inputFile.files, function() {
    if (this.size && maxFileSize && this.size > parseInt(maxFileSize)) {sizeExceeded=true;};
    extName = this.name.split('.').pop();
    if ($.inArray(extName.toLowerCase(), allowedExtension) == -1) {extError=true;};
  });
  if (sizeExceeded) {
    window.alert(maxExceededMessage);
    $(inputFile).val('');
  };

  if (extError) {
    window.alert(extErrorMessage);
    $(inputFile).val('');
  };
}

window.validateAudioFiles = function(inputFile) {
  var maxExceededMessage = "Tamanho máximo por aquivo (5 MB)";
  var extErrorMessage = "Apenas arquivos .odd .wav .mp3 são permitidos.";
  var allowedExtension = ["ogg", "wav", "mp3"];

  var extName;
  var maxFileSize = $(inputFile).data('max-file-size');
  var sizeExceeded = false;
  var extError = false;

  $.each(inputFile.files, function() {
    if (this.size && maxFileSize && this.size > parseInt(maxFileSize)) {sizeExceeded=true;};
    extName = this.name.split('.').pop();
    if ($.inArray(extName.toLowerCase(), allowedExtension) == -1) {extError=true;};
  });
  if (sizeExceeded) {
    window.alert(maxExceededMessage);
    $(inputFile).val('');
  };

  if (extError) {
    window.alert(extErrorMessage);
    $(inputFile).val('');
  };
}

window.searchSendWhatsappNumber = function () {
  // Declare variables
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById('searchInput');
  filter = input.value.toUpperCase();
  divs = document.getElementsByClassName('send_whatsapp_number_div');

  // Loop through all list items, and hide those who don't match the search query
  for (i = 0; i < divs.length; i++) {
    a = divs[i].getElementsByTagName("span")[0];
    txtValue = a.textContent || a.innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      divs[i].style.display = "";
    } else {
      divs[i].style.display = "none";
    }
  }
}
