window.addEventListener('turbolinks:load', () => {
  flatpickr(".datepicker", {
    enableTime: true,
    dateFormat: "d/m/Y H:i",
    minDate: "today",
    time_24hr: true,
    defaultDate: $("#automation_default_scheduled_at").val() != '' ? $("#automation_default_scheduled_at").val() : changeTimezone(new Date(), "America/Sao_paulo").getTime(),
    minuteIncrement: 1
  });

  if($("#automation_default_scheduled_at").val() != ''){
    $("#automation_scheduled_at").val($("#automation_default_scheduled_at").val())
  }

  $('#campaign_messages').on('cocoon:before-insert', function(e, insertedItem, originalEvent) {
    select = $(insertedItem).find('select')[0]
    $(select).on('change', '', function (e) {
      checkSelect(this)
    });
  });

  $('#campaign_messages').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    if($('.row_campaign_message_fields').not('[style*="display: none"]').length >= 5){
      $('.add_campaign_message_fields').hide()
    }
  });

  $('#campaign_messages').on('cocoon:after-remove', function(e, insertedItem, originalEvent) {
    if($('.row_campaign_message_fields').not('[style*="display: none"]').length < 5){
      $('.add_campaign_message_fields').show()
    }
  });

  $('.row_campaign_message_fields').on('change', '', function (e) {
    select = $(this).find('select')[0]
    checkSelect(select)
  });

  $('.row_campaign_message_fields').each(function(index, value) {
    select = $(value).find('select')[0]
    checkSelect(select)
  });

  if($('.row_campaign_message_fields').not('[style*="display: none"]').length < 5){
    $('.add_campaign_message_fields').show()
  }
})

function checkSelect(select){
  if($(select).val() == '2' || $(select).val() == '3' || $(select).val() == '4' || $(select).val() == '5' || $(select).val() == '12'){
    $(select).parent().next().addClass('col-lg-4')
    $(select).parent().next().removeClass('col-lg-7')
    $(select).parent().next().next().show()
    if($(select).val() == '2' || $(select).val() == '12'){
      $($(select).parent().next().next().find('input')[0]).attr("accept", '.png, .jpg, .jpeg')
    }else if($(select).val() == '3'){
      $($(select).parent().next().next().find('input')[0]).attr("accept", 'video/*')
    }else if($(select).val() == '4'){
      $($(select).parent().next().next().find('input')[0]).attr("accept", 'audio/*')
    }else if($(select).val() == '5'){
      $($(select).parent().next().next().find('input')[0]).attr("accept", '.pdf')
    }
  }else{
    $(select).parent().next().removeClass('col-lg-4')
    $(select).parent().next().addClass('col-lg-7')
    $(select).parent().next().next().hide()
  }
  if($(select).val() == '6'){
    $($(select).parent().next().find('textarea')[0]).attr("placeholder", 'Informe o título do grupo')
  }else if($(select).val() == '7'){
    $($(select).parent().next().find('textarea')[0]).attr("placeholder", 'Informe o descrição do grupo')
  }else if($(select).val() == '8' || $(select).val() == '9' || $(select).val() == '10' || $(select).val() == '11' || $(select).val() == '12'){
    $($(select).parent().next().find('textarea')[0]).attr("placeholder", '')
  }else if($(select).val() == '13'){
    $($(select).parent().next().find('textarea')[0]).attr("placeholder", 'Digite o título do botão na primeira linha\nDigite o link do botão na segunda linha')
  }else{
    $($(select).parent().next().find('textarea')[0]).attr("placeholder", 'Utilize {n} para pegar o número do grupo, exemplo: Nome do grupo #{n} ...')
  }
}

function changeTimezone(date, ianatz) {

  // suppose the date is 12:00 UTC
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: ianatz
  }));

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  var diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract

}

window.validateFiles = function(inputFile) {
  var maxExceededMessage = "Tamanho máximo por aquivo (15 MB)";
  var extErrorMessage = "Apenas esses arquivos .jpg, .jpeg, .gif, .png, .mp3, .mov, .ogg ou mp4 são permitidos.";
  var allowedExtension = ["jpg", "jpeg", "gif", "png", "mp3", "mp4", "pdf", "mov", "ogg"];

  var extName;
  var maxFileSize = $(inputFile).data('max-file-size');
  var sizeExceeded = false;
  var extError = false;

  $.each(inputFile.files, function() {
    if (this.size && maxFileSize && this.size > parseInt(maxFileSize)) {sizeExceeded=true;};
    extName = this.name.split('.').pop();
    if ($.inArray(extName.toLowerCase(), allowedExtension) == -1) {extError=true;};
  });
  if (sizeExceeded) {
    window.alert(maxExceededMessage);
    $(inputFile).val('');
  };

  if (extError) {
    window.alert(extErrorMessage);
    $(inputFile).val('');
  };
}
