$( document ).on('turbolinks:load', function() {
  if($('body').hasClass('webhooks')) {
    $('#webhook_platform_name').on('change', '', function (e) {
      check_ddi()
      check_hotmart_kiwify_eduzz()
    });
  }
  function check_ddi(){
    if($('#webhook_platform_name') && ($('#webhook_platform_name').val() == 'mailchimp' || $('#webhook_platform_name').val() == 'activecampaign')){
      $('.ddi').show()
    }else{
      $('.ddi').hide()
    }
  }
  function check_hotmart_kiwify_eduzz(){
    if($('#webhook_platform_name') && ($('#webhook_platform_name').val() == 'hotmart' || $('#webhook_platform_name').val() == 'kiwify' || $('#webhook_platform_name').val() == 'eduzz' || $('#webhook_platform_name').val() == 'asaas')){
      $('.hotmart_kiwify_eduzz').show()
    }else{
      $('.hotmart_kiwify_eduzz').hide()
    }
  }
  check_ddi()
  check_hotmart_kiwify_eduzz()
  window.copyToClipboard = function(text) {
    navigator.clipboard.writeText(text);
    toastr.success('Copiado', '', { closeButton: true, progressBar: true })
  }
})
